import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmationModal({ modal, setModal, cancelAction, confirmAction }) {
  return (
    <div>
      <Modal isOpen={modal} toggle={() => cancelAction()} backdrop="static">
        <ModalHeader toggle={() => setModal()}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to perform this action ?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => confirmAction()}>
            Yes
          </Button>{" "}
          <Button color="secondary" onClick={() => cancelAction()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
