import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Form, Row, FormGroup, Input, Label, Col, Button } from "reactstrap";
import ErrorMessage from "../../../utils/errorMessage";
import { RequiredField } from "../../../utils/fieldRequired";
import { Formik } from "formik";
import { Submit } from "../../../../constant";
import { validationSchema } from "./validationSchema";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../redux/apiCall";
import Select from "react-select";
import {
  get_appointments_types,
  get_branches,
  create_appointment,
  get_staff,
  get_services,
  appointment_details,
} from "../../../../constant/url";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";

import { AppointmentDetail } from "../AppointmentDetail";
import Swal from "sweetalert2";
export function CreateAppointmentModal({
  open,
  setOpen,
  getAppointments,
  service,
  clickedDate,
}) {
  const [appointmentTypes, setAppointmentTypes] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [selectedServices, setSelectedServices] = React.useState([]);
  const [staffList, setStaffList] = React.useState([]);
  const saloon = JSON.parse(localStorage.getItem("saloon"));
  const [currentValues, setCurrentValues] = React.useState(null);
  const [detailModal, setDetailModal] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    client_name: "",
    client_email: "",
    branch_id: "",
    appointment_type_id: "",
    appointment_time: new Date(),
    arrival_time: new Date(),
    contact_number: "",
    preferred_staff_id: "",
  });

  React.useEffect(() => {
    fetchBranches();
    fetchAppointmentTypes();
    fetchStaff();
  }, []);

  React.useEffect(() => {
    if (clickedDate) {
      setInitialValues({
        ...initialValues,
        appointment_time: clickedDate,
        arrival_time: clickedDate,
      });
    }
  }, [clickedDate]);
  React.useEffect(() => {
    if (open && !branches.length) {
      Swal.fire("Error", "Add branches to continue creating an appointment");
    }
  }, [open]);
  const dispatch = useDispatch();
  const fetchBranches = () => {
    dispatch(
      apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
        if (resp.status) {
          setBranches(resp.data);
          setInitialValues({ ...initialValues, branch_id: resp.data[0].id });
          fetchServicesByBranchInitially(resp.data[0].id);
        }
      })
    );
  };
  const fetchServicesByBranchInitially = (branch_id) => {
    dispatch(
      apiCall(get_services, "POST", { branch_id }, (resp) => {
        let result = [...resp.data];
        let options = [];
        for (let service of result) {
          options.push({
            value: service.id,
            label: service.name,
          });
        }
        setServices(options);
      })
    );
  };
  const fetchServicesByBranch = ({ currentTarget: input }) => {
    dispatch(
      apiCall(get_services, "POST", { branch_id: input.value }, (resp) => {
        let result = [...resp.data];
        let options = [];
        for (let service of result) {
          options.push({
            value: service.id,
            label: service.name,
          });
        }
        setServices(options);
      })
    );
  };
  const fetchAppointmentTypes = () => {
    dispatch(
      apiCall(get_appointments_types, "GET", {}, (resp) => {
        setAppointmentTypes(resp.data);
      })
    );
  };
  const handleServiceTypes = (elem) => {
    setSelectedServices(elem);
  };
  const fetchStaff = async () => {
    if (saloon) {
      dispatch(
        apiCall(
          get_staff,
          "POST",
          {
            page_number: 1,
            page_size: 100,
            sort_by: "id",
            order: "DESC",
          },
          (resp) => {
            if (resp.status) {
              setStaffList(resp.data.records);
            }
          }
        )
      );
    }
  };
  const fetchAppointmentById = async (id) => {
    dispatch(
      apiCall(appointment_details, "POST", { appointment_id: id }, (resp) => {
        setCurrentValues(resp.data);
        setDetailModal(true);
      })
    );
  };

  const onSubmit = (values, formActions) => {
    let _values = {
      ...values,
      appointment_time:
        parseInt(values.appointment_type_id) === 1
          ? moment(new Date()).format("YYYY-MM-DDTHH:mm")
          : moment(values.appointment_time).format("YYYY-MM-DDTHH:mm"),
      arrival_time:
        parseInt(values.appointment_type_id) === 1
          ? moment(new Date()).format("YYYY-MM-DDTHH:mm")
          : moment(values.arrival_time).format("YYYY-MM-DDTHH:mm"),
      client_phone: values.client_phone.replace(/^(0|\92)/, ""),
    };
    if (values.appointment_type_id === "1") {
      _values["status"] = "arrived";
    }
    let formData = new FormData();
    for (let key in _values) {
      formData.append(key, _values[key]);
    }
    formData.append("saloon_id", saloon.id);
    if (service) {
      formData.append("service_ids[]", service.id);
    } else {
      for (let item of selectedServices) {
        formData.append("service_ids[]", item.value);
      }
    }

    dispatch(
      apiCall(create_appointment, "POST", formData, (resp) => {
        if (resp.status) {
          formActions.setSubmitting(false);
          formActions.resetForm();
          getAppointments();
          setOpen(false);
          fetchAppointmentById(resp.data.id);

          toast.dark("Appointment created successfully!");
        } else {
          formActions.setSubmitting(false);
          toast.error(resp.message);
        }
      })
    );
  };
  const getBranchName = (id) => {
    return branches.find((branch) => parseInt(branch.id) === parseInt(id))
      ?.name;
  };
  const getAppointmentType = (id) => {
    return appointmentTypes.find((appt) => parseInt(appt.id) === parseInt(id))
      ?.name;
  };
  const getServicesName = () => {
    if (service) {
      return service.name;
    } else {
      let arr = [];
      for (let i = 0; i < selectedServices.length; i++) {
        arr.push(selectedServices[i].label);
      }
      return arr;
    }
  };
  return (
    <>
      <Modal isOpen={open} toggle={setOpen} size="lg">
        <ModalHeader toggle={setOpen}>Create Appointment</ModalHeader>
        <ModalBody>
          {service && (
            <h6>
              <small>You are currently making booking for</small>{" "}
              {service?.name}
            </h6>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              isValid,
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="p-3" onSubmit={handleSubmit} id="reciept">
                <Row>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"client_name"}>
                        Name <RequiredField />
                      </Label>
                      <Input
                        name={"client_name"}
                        onChange={handleChange}
                        type={"text"}
                        size={"sm"}
                        value={values["client_name"]}
                      />
                      {errors["client_name"] && touched["client_name"] && (
                        <ErrorMessage message={errors["client_name"]} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"client_phone"}>
                        Client Phone <RequiredField />
                      </Label>
                      <Input
                        name={"client_phone"}
                        onChange={handleChange}
                        type={"text"}
                        size={"sm"}
                        value={values["client_phone"]}
                      />
                      {errors["client_phone"] && touched["client_phone"] && (
                        <ErrorMessage message={errors["client_phone"]} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"client_email"}>Email</Label>
                      <Input
                        name={"client_email"}
                        onChange={handleChange}
                        type={"text"}
                        size={"sm"}
                        value={values["client_email"]}
                      />
                      {errors["client_email"] && touched["client_email"] && (
                        <ErrorMessage message={errors["client_email"]} />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"appointment_type_id"}>
                        Appointment type <RequiredField />
                      </Label>

                      {appointmentTypes.map((appointment, index) => (
                        <div
                          className="form-check form-check-inline d-flex align-iems-center"
                          key={index}
                        >
                          <Input
                            type="radio"
                            name={"appointment_type_id"}
                            value={appointment.id}
                            onChange={handleChange}
                          />{" "}
                          <Label className="mb-0">{appointment.title}</Label>
                        </div>
                      ))}
                      {/* <Input
                        name={"appointment_type_id"}
                        onChange={handleChange}
                        type={"select"}
                        size={"sm"}
                      >
                        <option> Select....</option>
                        {appointmentTypes.map((appointment, index) => (
                          <option value={appointment.id}>
                            {appointment.title}
                          </option>
                        ))}
                      </Input> */}
                      {errors["appointment_type_id"] &&
                        touched["appointment_type_id"] && (
                          <ErrorMessage
                            message={errors["appointment_type_id"]}
                          />
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"name"}>
                        Branch <RequiredField />
                      </Label>
                      <Input
                        disabled={!branches.length}
                        name={"branch_id"}
                        onChange={(e) => {
                          handleChange(e);
                          fetchServicesByBranch(e);
                        }}
                        type={"select"}
                        size={"sm"}
                        value={values.branch_id}
                      >
                        <option> Select....</option>
                        {branches.map((branch, index) => (
                          <option value={branch.id}>{branch.name}</option>
                        ))}
                      </Input>

                      {errors["branch_id"] && touched["branch_id"] && (
                        <ErrorMessage message={errors["branch_id"]} />
                      )}
                    </FormGroup>
                  </Col>

                  {service ? null : (
                    <Col md="6" sm="6" xs="12">
                      <FormGroup>
                        <Label for={"name"}>
                          Required Services <RequiredField />
                        </Label>
                        <Select
                          isMulti
                          name="service_ids"
                          onChange={handleServiceTypes}
                          closeMenuOnSelect={false}
                          options={services}
                          classNamePrefix="service_type"
                        />
                        {/* {!selectedServices && (
                                        <ErrorMessage message={errors["service_ids"]} />
                                    )} */}
                      </FormGroup>
                    </Col>
                  )}
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"preferred_staff_id"}>Preffered Staff</Label>
                      <Input
                        name={"preferred_staff_id"}
                        onChange={handleChange}
                        type={"select"}
                        size={"sm"}
                        value={values["preferred_staff_id"]}
                      >
                        <option value="">Select Staff</option>
                        {staffList.map((item, index) => (
                          <option value={item.staff_id} key={index}>
                            {item.first_name + " " + item.last_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"appointment_time"}>
                        Appointment Date & Time <RequiredField />
                      </Label>
                      <DatePicker
                        name="appointment_time"
                        className="form-control datepicker"
                        disabled={parseInt(values["appointment_type_id"]) === 1}
                        onChange={(dateTime) => {
                          setFieldValue("appointment_time", dateTime);
                        }}
                        dateFormat="Pp"
                        showTimeSelect
                        selected={values["appointment_time"]}
                      />
                      {/* <Input
                        name={"appointment_time"}
                        onChange={handleChange}
                        type={"datetime-local"}
                        size={"sm"}
                        value={values["appointment_time"]}
                      /> */}
                      {errors["appointment_time"] &&
                        touched["appointment_time"] && (
                          <ErrorMessage message={errors["appointment_time"]} />
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"arrival_time"}>
                        Arrival Date & Time <RequiredField />
                      </Label>
                      <DatePicker
                        name="arrival_time"
                        className="form-control datepicker"
                        disabled={parseInt(values["appointment_type_id"]) === 1}
                        onChange={(dateTime) => {
                          setFieldValue("arrival_time", dateTime);
                        }}
                        dateFormat="Pp"
                        showTimeSelect
                        selected={values["arrival_time"]}
                      />
                      {/* <Input
                        name={"arrival_time"}
                        onChange={handleChange}
                        type={"datetime-local"}
                        size={"sm"}
                        value={values["arrival_time"]}
                      /> */}
                      {errors["arrival_time"] && touched["arrival_time"] && (
                        <ErrorMessage message={errors["arrival_time"]} />
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6" sm="6" xs="12">
                    <FormGroup>
                      <Label for={"priority"}>Priority</Label>
                      <Input
                        name={"priority"}
                        onChange={handleChange}
                        type={"select"}
                        size={"sm"}
                        value={values["priority"]}
                      >
                        <option value="">Select priority</option>
                        <option value="high">High</option>
                        <option value="medium">Medium</option>
                        <option value="low">Low</option>
                      </Input>
                      {errors["priority"] && touched["priority"] && (
                        <ErrorMessage message={errors["priority"]} />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-right">
                  <Button
                    color="primary"
                    className="mr-1"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    {Submit}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      <AppointmentDetail
        open={detailModal}
        setOpen={() => setDetailModal(!detailModal)}
        appointment={currentValues}
        handleAppointmentStatus={null}
      />
    </>
  );
}
