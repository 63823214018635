import moment from "moment";
import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";
import * as htmlToImage from "html-to-image";
import { Checkout } from "./Checkout";
import { Formik } from "formik";
import { Edit } from "react-feather";
import { apiCall } from "../../../redux/apiCall";
import { useDispatch } from "react-redux";
import { appointment_status_list } from "../../../constant/url";

export function AppointmentDetail({
  open,
  setOpen,
  appointment,
  setOpenEdit,
  handleAppointmentStatus,
}) {
  const [checkoutModal, setCheckoutModal] = React.useState(false);
  const [status_list, setStatusList] = React.useState(null);
  const [disableStatus, setDisableStatus] = React.useState(false);
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return <span className="badge badge-primary">Pending</span>;

      case "booked":
        return <span class="badge badge-secondary">Booked</span>;

      case "arrived":
        return <span class="badge badge-warning">Arrived</span>;

      case "in_progress":
        return <span class="badge badge-info">In Progress</span>;

      case "no_show":
        return <span class="badge badge-light">No Show</span>;

      case "canceled":
        return <span class="badge badge-danger">Cancelled</span>;

      case "done":
        return <span class="badge badge-success">Done</span>;
    }
  };

  const saveReceipt = () => {
    htmlToImage
      .toPng(ref.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = `${appointment?.appointment_details?.appointment_time}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };
  const fetchStatus = () => {
    dispatch(
      apiCall(appointment_status_list, "GET", {}, (resp) => {
        setStatusList(resp.data.status);
      })
    );
  };
  React.useEffect(() => {
    fetchStatus();
  }, []);
  return (
    <>
      <Modal isOpen={open} toggle={setOpen} size="lg">
        <ModalHeader toggle={setOpen}>Appointment Detail</ModalHeader>
        <ModalBody>
          <div className="p-4">
            <div
              ref={ref}
              style={{ backgroundColor: "white", padding: "10px" }}
            >
              <div>
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <h6>
                    Appointment{" "}
                    <Edit
                      onClick={() => {
                        setOpen();
                        setOpenEdit();
                      }}
                      height={14}
                      className="cursor-pointer"
                    />{" "}
                  </h6>
                  {handleAppointmentStatus && (
                    <div>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          handleAppointmentStatus(e);
                          e.target.value === "completed"
                            ? setDisableStatus(true)
                            : setDisableStatus(false);
                        }}
                        disabled={
                          appointment?.appointment_details?.status ===
                            "completed" || disableStatus
                        }
                      >
                        {status_list &&
                          Object.keys(status_list).map((option) => (
                            <option
                              value={status_list[option]}
                              selected={
                                status_list[option] ===
                                appointment?.appointment_details?.status
                              }
                            >
                              {option}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                <table className="table table-bordered mt-1">
                  <tbody>
                    <tr>
                      <td>Type</td>
                      <td>
                        {
                          appointment?.appointment_details?.appointment_type
                            ?.title
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Time</td>
                      <td>
                        {moment(
                          appointment?.appointment_details?.appointment_time
                        ).format("Do MMMM, YY [at] h:mm a")}
                      </td>
                    </tr>
                    <tr>
                      <td>Total Price</td>
                      <td>
                        Rs {appointment?.appointment_details?.total_price}
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        {" "}
                        {getStatusBadge(
                          appointment?.appointment_details?.status
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="my-3">
                <h6>Opted Services</h6>
                <div class="d-flex flex-wrap">
                  {appointment?.appointment_details?.services.map((service) => (
                    <span
                      class="badge bg-dark m-1 p-2"
                      style={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      {service?.name}
                    </span>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <h6>Client Information</h6>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {appointment?.appointment_details?.client_details?.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        {
                          appointment?.appointment_details?.client_details
                            ?.email
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-2">
                <h6>Branch Information</h6>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {appointment?.appointment_details?.branchDetails?.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>
                        {
                          appointment?.appointment_details?.branchDetails
                            ?.address
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {!handleAppointmentStatus && (
              <div className="d-flex w-100 justify-content-end mt-1">
                <button
                  className="btn btn-dark"
                  type="button"
                  onClick={() => saveReceipt()}
                >
                  Save Details
                </button>
              </div>
            )}
            {handleAppointmentStatus && (
              <div className="mt-2">
                <h6 id="toggler" style={{ cursor: "pointer" }}>
                  View History
                </h6>
                <UncontrolledCollapse toggler="#toggler" caret>
                  <table className="table table-bordered">
                    <thead>
                      <th>Time</th>
                      <th>Type</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {appointment?.appointment_history.map(
                        (history, index) => (
                          <tr>
                            <td>
                              {moment(history.appointment_time).format(
                                "Do MMMM, YY [at] h:mm a"
                              )}
                            </td>
                            <td>{history.appointment_type}</td>
                            <td> {getStatusBadge(history.status)}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </UncontrolledCollapse>
              </div>
            )}
          </div>
          {/* {appointment?.appointment_details?.status === "done" && (
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                onClick={() => {
                  setOpen(false);
                  setCheckoutModal(true);
                }}
              >
                Checkout
              </Button>
            </div>
          )} */}
        </ModalBody>
      </Modal>
      <Checkout
        open={checkoutModal}
        setOpen={() => setCheckoutModal(!checkoutModal)}
        appointment={appointment}
        closeDetailModal={() => setOpen(false)}
      />
    </>
  );
}
