import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
// import SortBy from '../../../../common/sortBy';

import { useHistory } from "react-router-dom";
import TableComponent from "../../../common/TableComponent";
import Spinner from "../../../common/spinner";
import ConfirmationModal from "../../Modals/Confirmation";
import { useDispatch } from "react-redux";
import { apiCall } from "../../../../redux/apiCall";
import { delete_categories } from "../../../../constant/url";
import { toast } from "react-toastify";

const CategoryData = (props) => {
  const [data, setData] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAction = (id) => {
    history.push(`/branch/edit-branch/${id}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: false,
    },
    // {
    //     name: 'Employee Count',
    //     selector: 'employee_count',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'Address',
    //     selector: 'address',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'No. of Student',
    //     selector: 'studentsCount',
    //     sortable: true,
    //     center: true,
    // },
    // {
    //     name: 'No. of Teacher',
    //     selector: 'teachersCount',
    //     sortable: true,
    //     center: true,
    // },
    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var BranchData = [];
    const data = props.categories;
    data.forEach((element) => {
      const mappedData = {
        name: element.name,
        id: element.id,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/category/edit`,
                state: {
                  category: element,
                },
              })
            }
          ></i>,
          <i
            className="ml-3 fa fa-trash font-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setToBeDeleted(element);
              setOpenConfirmation(true);
            }}
          ></i>,
        ],
      };
      BranchData.push(mappedData);
    });

    setData(BranchData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };
  const removeCategory = () => {
    dispatch(
      apiCall(
        `${delete_categories}/${toBeDeleted.id}`,
        "DELETE",
        {},
        (resp) => {
          if (resp && resp.status === 200) {
            const _data = data.filter((elem) => elem.id !== toBeDeleted.id);
            setData(_data);
            toast.dark("Category removed!");
          } else {
            toast.error(resp.message);
          }
          setOpenConfirmation(false);
          setToBeDeleted(null);
        }
      )
    );
  };
  const cancelActionOnConfirmation = () => {
    setOpenConfirmation(false);
    setToBeDeleted(null);
  };

  const confirmActionOnConfirmation = () => {
    removeCategory();
  };
  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
        />
      )}
      <ConfirmationModal
        modal={openConfirmation}
        setModal={() => setOpenConfirmation(!openConfirmation)}
        cancelAction={cancelActionOnConfirmation}
        confirmAction={confirmActionOnConfirmation}
      />
    </Fragment>
  );
};

export default CategoryData;
