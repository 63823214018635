import React, { useEffect, Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Dragging_Event } from "../../../constant";
import Breadcrumb from "../../../layout/breadcrumb";
import { CreateAppointmentModal } from "./appointment-modal/CreateAppointmentModal";
import { apiCall } from "../../../redux/apiCall";
import {
  get_branches,
  get_appointments_paginated,
  reschedule_appointment,
  appointment_details,
  update_appointment_status,
} from "../../../constant/url";
import { useDispatch } from "react-redux";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { toast } from "react-toastify";
import Switch from "react-switch";
import { AppointmentDetail } from "./AppointmentDetail";
import { PriceModal } from "./price-modal/PriceModal";
import { Calendar, List } from "react-feather";
import { AppointmentList } from "./AppointmentList";
import { EditAppointmentModal } from "./appointment-modal/EditAppointmentModal";
import { Checkout } from "./Checkout";
const Appointments = () => {
  const [open, setOpen] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [amountModal, setAmountModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [branches, setBranches] = useState([]);
  const saloon = JSON.parse(localStorage.getItem("saloon"));
  const [appointmentDetailToView, setAppointmentDetailToView] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [clickedDate, setClickedDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const dateRange = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Yesterday",
      value: "yesterday",
    },
    {
      label: "Last 7 days",
      value: "7days",
    },
    {
      label: "Last 30 days",
      value: "30days",
    },
    {
      label: "Custom",
      value: "custom",
    },
  ];

  const [pagination, setPagination] = useState({
    page_number: 1,
    page_size: 100,
    order: "DESC",
    branch_id: null,
    dateRange: "monthly",
    startDate: moment(new Date()),
    endDate: moment(new Date()).add(1, "month"),
  });
  useEffect(() => {
    fetchBranches();
  }, []);
  useEffect(() => {
    if (pagination.branch_id) {
      getAppointments();
    }
  }, [pagination]);
  const getAppointments = () => {
    setLoading(true);
    dispatch(
      apiCall(get_appointments_paginated, "POST", pagination, (resp) => {
        if (resp.status) {
          let list = resp.data.records;
          let appointments = [];
          for (let item of list) {
            appointments.push({
              id: item.id,
              title: item?.client_details?.name,
              start: new Date(item?.appointment_time),
              // endDate: new Date(moment(item?.appointment_time).add(moment.duration(30, 'minute')).format("YYYY-MM-DD HH:mm:ss"))
            });
          }
          setAppointments(resp.data);
          setEvents(appointments);
          setLoading(false);
        }
      })
    );
  };
  const fetchBranches = () => {
    dispatch(
      apiCall(get_branches, "POST", { saloon_id: saloon.id }, (resp) => {
        if (resp.status) {
          setBranches(resp.data);
          let _pagination = { ...pagination };
          if (resp.data.length) {
            _pagination["branch_id"] = resp.data[0].id;
            setPagination(_pagination);
          }
        }
      })
    );
  };
  const rescheduleEvent = (date, id) => {
    dispatch(
      apiCall(
        reschedule_appointment,
        "POST",
        { appointment_id: id, rescheduled_appointment_time: date },
        (resp) => {
          if (resp.status) {
            toast.dark("Event rescheduled!");
          } else {
            toast.error(resp.message);
          }
        }
      )
    );
  };
  const eventClick = (eventClick) => {
    dispatch(
      apiCall(
        appointment_details,
        "POST",
        { appointment_id: eventClick.event.id },
        (resp) => {
          localStorage.setItem("appointment", JSON.stringify(resp?.data));
          setAppointmentDetailToView(resp?.data);
          setDetailModal(true);
        }
      )
    );
  };
  const eventClickListView = (id) => {
    dispatch(
      apiCall(appointment_details, "POST", { appointment_id: id }, (resp) => {
        localStorage.setItem("appointment", JSON.stringify(resp.data));
        setAppointmentDetailToView(resp.data);
        setDetailModal(true);
      })
    );
  };
  const updateAppointmentStatus = (input) => {
    let payload = {
      status: input,
      appointment_id: appointmentDetailToView?.appointment_details?.id,
    };
    dispatch(
      apiCall(update_appointment_status, "POST", payload, (resp) => {
        if (resp.status === 200) {
          const _appointmentDetailToView = { ...appointmentDetailToView };
          _appointmentDetailToView.appointment_details.status = input;
          setAppointmentDetailToView({ ..._appointmentDetailToView });

          setAmountModal(false);

          toast.dark("Appointment Status Updated!");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  const handleAppointmentStatus = ({ currentTarget: input }) => {
    if (input.value === "completed") {
      setAmountModal(true);
    } else {
      updateAppointmentStatus(input.value);
    }
    // updateAppointmentStatus(input.value);
  };
  const handleDoneStatus = (value) => {
    let payload = {
      status: "completed",
      appointment_id: appointmentDetailToView?.appointment_details?.id,
      ...value,
    };
    dispatch(
      apiCall(update_appointment_status, "POST", payload, (resp) => {
        if (resp.status === 200) {
          const _appointmentDetailToView = { ...appointmentDetailToView };
          _appointmentDetailToView.appointment_details.status = "completed";
          setAppointmentDetailToView({ ..._appointmentDetailToView });
          setAmountModal(false);

          toast.dark("Appointment Status Updated!");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };
  const handleDateClick = (date) => {
    const clickedDate = new Date(date);

    // Set the time part to the current time
    const currentDate = new Date();
    clickedDate.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
    setClickedDate(clickedDate);
    setOpen(true);
  };
  return (
    <Fragment>
      <Breadcrumb parent="Admin" title="Appointments" />
      <Container fluid={true}>
        <Row>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="d-flex w-100 justify-content-around align-items-center">
              <FormGroup>
                <Label>View</Label>
                <div className="d-flex mb-1 align-items-center">
                  <List height={"20px"} className="mb-2" color="#262932" />

                  <div className="mx-2">
                    {" "}
                    <Switch
                      onColor="unset"
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e);
                        setPagination({ ...pagination, page_size: 10 });
                      }}
                      handleDiameter={15}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={25}
                      width={50}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                  <Calendar height={"20px"} className="mb-2" color="#262932" />
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Branches</Label>
                <Input
                  name={"branch_id"}
                  onChange={(e) =>
                    setPagination({ ...pagination, branch_id: e.target.value })
                  }
                  type={"select"}
                  size={"sm"}
                >
                  <option> Branches</option>
                  {branches.map((branch, index) => (
                    <option
                      value={branch.id}
                      key={index}
                      selected={pagination.branch_id === branch.id}
                    >
                      {" "}
                      {branch.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Filter By</Label>
                <Input
                  name={"branch_id"}
                  onChange={(e) =>
                    setPagination({ ...pagination, dateRange: e.target.value })
                  }
                  type={"select"}
                  size={"sm"}
                >
                  <option disabled> Filter by...</option>
                  {dateRange.map((range, index) => (
                    <option
                      value={range.value}
                      key={index}
                      selected={pagination.dateRange === range.value}
                    >
                      {range.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </div>
            <div
              className="d-flex w-100 justify-content-around align-items-end"
              style={{ marginBottom: "15px" }}
            >
              <div style={{ width: "210px" }}>
                {pagination.dateRange === "custom" && (
                  <>
                    <Label>Custom Range</Label>
                    <DateRangePicker
                      value={[
                        moment(pagination.startDate),
                        moment(pagination.endDate),
                      ]}
                      className={["custom-range"]}
                      calendarIcon={null}
                      onChange={(value) =>
                        setPagination({
                          ...pagination,
                          startDate: moment(value[0]).format(
                            "YYYY-MM-DD[T]HH:mm:ss"
                          ),
                          endDate: moment(value[1]).format(
                            "YYYY-MM-DD[T]HH:mm:ss"
                          ),
                        })
                      }
                    />
                  </>
                )}
              </div>
              <div>
                <Button
                  color="dark"
                  onClick={() => setOpen(!open)}
                  className="mt-2"
                >
                  Add Walk-in Client{" "}
                </Button>
              </div>
            </div>
          </div>
          {loading && (
            <div className="d-flex w-100 justify-content-center align-items-center my-2">
              <Spinner color="darl" size="sm" type="grow" /> Updating...
            </div>
          )}
          <Col sm="12">
            <Card>
              {/* <CardHeader>
                            </CardHeader> */}
              <CardBody>
                <div className="basic-calendar">
                  <Row>
                    <Col xl={12} md={12}>
                      {!isChecked ? (
                        <AppointmentList
                          appointments={appointments}
                          eventClickListView={eventClickListView}
                          pagination={pagination}
                          setPagination={setPagination}
                        />
                      ) : (
                        <div className="demo-app-calendar" id="mycalendartest">
                          <FullCalendar
                            defaultView="dayGridMonth"
                            headerToolbar={{
                              // left: "prev, next, today",
                              // center: "title",
                              right:
                                "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                            }}
                            renderDelay={10}
                            eventDurationEditable={false}
                            editable={true}
                            dateClick={(resp) => handleDateClick(resp.date)}
                            droppable={true}
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                            ]}
                            events={events}
                            eventClick={eventClick}
                            eventDrop={(e) =>
                              rescheduleEvent(e.event.startStr, e.event.id)
                            }
                            datesSet={(values) => {}}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <CreateAppointmentModal
        open={open}
        setOpen={() => setOpen(!open)}
        getAppointments={getAppointments}
        clickedDate={clickedDate}
      />
      <AppointmentDetail
        open={detailModal}
        setOpen={() => setDetailModal(!detailModal)}
        appointment={appointmentDetailToView}
        setOpenEdit={() => setOpenEditModal(!openEditModal)}
        handleAppointmentStatus={handleAppointmentStatus}
      />
      <PriceModal
        open={amountModal}
        setOpen={() => setAmountModal(!amountModal)}
        updateStatus={updateAppointmentStatus}
        handleSubmit={handleDoneStatus}
      />
      <EditAppointmentModal
        open={openEditModal}
        setOpen={() => setOpenEditModal(!openEditModal)}
        getAppointments={getAppointments}
        appointment={appointmentDetailToView}
        clickedDate={clickedDate}
      />
      <Checkout
        open={amountModal}
        setOpen={() => setAmountModal(!amountModal)}
        appointment={appointmentDetailToView}
        closeDetailModal={() => setOpen(false)}
        callBack={() => {
          const _appointmentDetailToView = { ...appointmentDetailToView };
          _appointmentDetailToView.appointment_details.status = "completed";
          setAppointmentDetailToView({ ..._appointmentDetailToView });
          setAmountModal(false);
        }}
      />
    </Fragment>
  );
};

export default Appointments;
