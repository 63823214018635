import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../../layout/breadcrumb";
import { Formik } from "formik";
import { RequiredField } from "../../utils/fieldRequired";
// import { apiCall } from "../../../../redux/apiCall";
import * as Yup from "yup";
import { apiCall } from "../../../redux/apiCall";
import { toast } from "react-toastify";
import ErrorMessage from "../../utils/errorMessage";

export function Settings() {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required!"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
        "Password must include at least one uppercase letter, one lowercase letter, and one symbol"
      ),
    password_confirmation: Yup.string()
      .required("Password confirmation is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const onSubmit = (values, formActions) => {
    try {
      dispatch(
        apiCall("/update-password/submit", "POST", values, (resp) => {
          if (resp.status) {
            formActions.setSubmitting(false);
            formActions.resetForm();
            toast.success(`Password updated successfully!`);
          } else {
            formActions.setSubmitting(false);
            toast.error(resp.message);
          }
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Fragment>
      <Breadcrumbs parent="dashboard" title={"Settings"} />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-4">Update Password</CardHeader>
              <CardBody className="pt-0">
                <Row className="p-3">
                  <Col sm="12">
                    <Formik
                      initialValues={{
                        current_password: "",
                        password: "",
                        password_confirmation: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      enableReinitialize
                    >
                      {({
                        values,
                        errors,
                        touched,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <Form className="p-3" onSubmit={handleSubmit}>
                          <Row>
                            <Col md="6" sm="6" xs="12">
                              <FormGroup>
                                <Label for={"current_password"}>
                                  Current Password <RequiredField />
                                </Label>
                                <Input
                                  name={"current_password"}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  type={"text"}
                                  size={"sm"}
                                  placeholder={"Current Password"}
                                  value={values["current_password"]}
                                />
                                {errors["current_password"] &&
                                  touched["current_password"] && (
                                    <ErrorMessage
                                      message={errors["current_password"]}
                                    />
                                  )}
                              </FormGroup>
                            </Col>
                            <Col md="6" sm="6" xs="12">
                              <FormGroup>
                                <Label for={"password"}>
                                  Password <RequiredField />
                                </Label>
                                <Input
                                  name={"password"}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  type={"text"}
                                  size={"sm"}
                                  placeholder={"Password"}
                                  value={values["password"]}
                                />
                                {errors["password"] && touched["password"] && (
                                  <ErrorMessage message={errors["password"]} />
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6" sm="6" xs="12">
                              <FormGroup>
                                <Label for={"password_confirmation"}>
                                  Password Confirmation <RequiredField />
                                </Label>
                                <Input
                                  name={"password_confirmation"}
                                  onChange={handleChange}
                                  disabled={isSubmitting}
                                  type={"text"}
                                  size={"sm"}
                                  placeholder={"Password Confirmation"}
                                  value={values["password_confirmation"]}
                                />
                                {errors["password_confirmation"] &&
                                  touched["password_confirmation"] && (
                                    <ErrorMessage
                                      message={errors["password_confirmation"]}
                                    />
                                  )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                                <Spinner className="mr-2" size={"sm"} />
                              )}
                              Update Password
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
