import React, { Fragment } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";
// import SortBy from '../../../../common/sortBy';

import { useHistory } from "react-router-dom";
import TableComponent from "../../../common/TableComponent";
import Spinner from "../../../common/spinner";
import ConfirmationModal from "../../Modals/Confirmation";
import { useDispatch } from "react-redux";
import { delete_services } from "../../../../constant/url";
import { apiCall } from "../../../../redux/apiCall";
import { toast } from "react-toastify";
const ServiceData = (props) => {
  const [data, setData] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleAction = (id) => {
    history.push(`/branch/edit-branch/${id}`);
    // props.onClickAction(id)
  };
  const schoolTableColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: false,
    },

    {
      name: "Actions",
      selector: "actions",
      center: true,
    },
  ];

  useEffect(() => {
    var BranchData = [];
    const data = props.services;
    data.forEach((element) => {
      const mappedData = {
        name: element.name,
        id: element.id,
        actions: [
          <i
            className="fa fa-pencil font-primary"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/service/edit`,
                state: {
                  service: element,
                },
              })
            }
          ></i>,
          <i
            className="ml-3 fa fa-trash font-danger"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setToBeDeleted(element);
              setOpenConfirmation(true);
            }}
          ></i>,
        ],
      };
      BranchData.push(mappedData);
    });

    setData(BranchData);
  }, [props]);

  const tableConfig = {
    tableColumns: schoolTableColumns,
    data,
    onClickPagination: props.onClickPagination,
    paginationConfig: props.paginationConfig,
  };
  const removeService = () => {
    dispatch(
      apiCall(`${delete_services}/${toBeDeleted.id}`, "DELETE", {}, (resp) => {
        if (resp && resp.status === 200) {
          const _data = data.filter((elem) => elem.id !== toBeDeleted.id);
          setData(_data);
          toast.dark("Service removed!");
        } else {
          toast.error(resp.message);
        }
        setOpenConfirmation(false);
        setToBeDeleted(null);
      })
    );
  };
  const cancelActionOnConfirmation = () => {
    setOpenConfirmation(false);
    setToBeDeleted(null);
  };

  const confirmActionOnConfirmation = () => {
    removeService();
  };
  return (
    <Fragment>
      {props.loading ? (
        <Spinner />
      ) : (
        <TableComponent
          loading={props.loading}
          tableConfig={tableConfig}
          numPages={props.data?.numPages}
          setPaginationConfig={props.setPaginationConfig}
          staticPagination={true}
        />
      )}
      <ConfirmationModal
        modal={openConfirmation}
        setModal={() => setOpenConfirmation(!openConfirmation)}
        cancelAction={cancelActionOnConfirmation}
        confirmAction={confirmActionOnConfirmation}
      />
    </Fragment>
  );
};

export default ServiceData;
